@import "../website-v2/_variables.less";

.tabs {
	--gap-medium: 1rem;
	--gap-large: 2rem;
	--duration-default: 500ms;
	--duration-fast: 400ms;

	--tabs-amount: 0;
	--active-tab-index: 0;
	--tab-width: calc(100% / var(--tabs-amount));
	--tabs-translate-value: calc((var(--active-tab-index) * (var(--tab-width) + var(--gap-medium) / var(--tabs-amount))) * (-1));

	display: flex;
	flex-direction: column;
	gap: var(--gap-large);
	padding: var(--gap-medium);
	position: relative;

	&__container {
		position: relative;

		@media (min-width: @screen-lg) {
			border-radius: 0.93875rem;
			background: var(--green-blue-gradient);
			overflow: hidden;
		}

		&--outer-border,
		&--inner-border {
			@media (min-width: @screen-lg) {
				border-radius: 1.25rem 1.25rem 0 0;
				border: 0.0625rem dashed rgba(31, 192, 255, 0.50);
				border-bottom: 0;
				border-image-source: url(https://a.storyblok.com/f/185929/68x66/03410f4ac5/border-pattern.svg);
				border-image-outset: 0;
				border-image-width: 34px 33px 0;
				border-image-repeat: round;
				border-image-slice: 25;
				// Hides border image source
				overflow: hidden;
			}
		}

		&--outer-border {
			@media (min-width: @screen-lg) {
				padding: 2.12rem 1.87rem 0;

				&::after {
					content: '';
					display: block;
					position: absolute;
					width: 100%;
					padding-bottom: 10rem;
					background: var(--background-primary);
					background: var(--fade-to-primary-gradient);
					bottom: 0;
					left: 0;
				}
			}
		}

		&--inner-border {
			@media (min-width: @screen-lg) {
				border-image-outset: 0.0625rem;
				padding-bottom: 10rem;
			}
		}

		&--reel {
			gap: var(--gap-medium);
			display: flex;
			width: calc((100% * var(--tabs-amount)));
			transform: translateX(var(--tabs-translate-value));
			transition: transform var(--duration-default) ease-in-out;
		}
	}

	&__nav {
		display: none;

		@media (min-width: @screen-lg) {
			display: flex;
			gap: 0.5rem;
			width: 100%;
			overflow-x: scroll;
			/* Internet Explorer 10+ */
			-ms-overflow-style: none;
			/* Firefox */
			scrollbar-width: none;

			/* Safari and Chrome */
			&::-webkit-scrollbar {
				display: none;
			}
		}
	}

	&__button {
		padding: 0.25rem 1rem;
		color: var(--color-text-secondary);
		transition: var(--duration-default) ease-in-out;
		transition-property: border, background;
		display: flex;
		gap: 1.44rem;
		align-items: center;
		border-radius: 0.625rem;
		border: 0.0625rem solid #1C3F59;
		background: rgba(19, 40, 56, 0.20);
		max-width: 16.75rem;
		flex-shrink: 0;

		&:focus,
		&:focus-visible {
			border: 0.0625rem solid #1FC0FF;
			outline: none;
		}

		&:hover {
			border: 0.0625rem solid #1FC0FF;
			background: rgba(25, 51, 75, 0.40);
		}

		&.is-active {
			pointer-events: none;
			border: 0.0625rem solid #1FC0FF;
			background: rgba(25, 51, 75, 0.40);
		}

		&-text {
			display: flex;
			flex-direction: column;
			text-align: left;
		}

		&-label {
			color: var(--text-primary);
			font-size: 1rem;
			font-weight: 500;
			line-height: 140%;

			&--secondary {
				color: var(--octo-color-text-normal);
				font-size: 0.875rem;
				font-weight: 400;
			}
		}

		img {
			max-width: 2rem;
		}
	}

	&__card {
		flex: 1;
		display: flex;
		flex-direction: column;
		gap: 1.38rem;

		// Due to reboot.scss
		&[hidden] {
			display: flex !important;

			.tabs__card-label,
			.tabs__card-content {
				opacity: 0.3;

				@media (min-width: @screen-lg) {
					opacity: 1;
				}
			}
		}

		@media (min-width: @screen-lg) {
			margin: 3.3rem 1.69rem 0;
			border-radius: 1.05444rem;
		}

		&-description {
			color: var(--color-text-secondary);
		}

		&-label {
			color: var(--text-primary);
			font-size: 1.25rem;
			font-weight: 500;
			line-height: 140%;
			transition: opacity var(--duration-default) ease-in-out;
			overflow-y: hidden;

			&-wrapper {
				z-index: -1;
				border-bottom: solid 0.25rem #1C3950;
				position: relative;
				height: 3rem;
				padding-bottom: 1rem;
				box-sizing: content-box;

				@media (min-width: @screen-lg) {
					display: none;
				}

				&:not(.tabs__card-label-wrapper--underline)::after {
					position: absolute;
					content: '';
					display: block;
					height: 0.25rem;
					width: var(--gap-medium);
					background-color: #1C3950;
					left: calc(-1 * var(--gap-medium));
					bottom: -0.25rem;
				}

				&--underline {
					z-index: 0;

					&::after {
						position: absolute;
						content: '';
						display: block;
						height: 0.1875rem;
						width: 100%;
						background-color: #17B9EC;
						transform: translateX(calc(-1 * ((var(--active-tab-index) * (100% + var(--gap-medium))) * (-1)))) scaleX(0.5);
						transform-origin: left;
						transition: transform var(--duration-fast) ease-in-out;
						bottom: -0.21875rem;
						border-radius: 0.1875rem;
					}
				}
			}
		}

		.tabs__card-content {
			border-radius: 0.71963rem;
			background: var(--green-blue-gradient);
			flex: 1;
			transition: opacity var(--duration-default) ease-in-out;
			padding: 0.84rem;

			@media (min-width: @screen-lg) {
				border-radius: initial;
				background: initial;
				padding: 0;
			}

			&>* {
				align-self: start;
			}

			&--columns {
				display: grid;
				grid-template-columns: auto;
				grid-template-rows: min-content;
				gap: var(--space-40);

				@media (min-width: @screen-lg) {
					grid-template-columns: 2fr 1fr;
				}

				// Styles for nested Free Tool component
				.free-tool {
					&--transparent {
						@media (max-width: @screen-lg) {
							order: -1;
							background-color: transparent;
							border: none;

							.free-tool__heading,
							.free-tool__icon {
								display: none;
							}

							.free-tool__description {
								color: #0E1924;
							}
						}

						.free-tool__description {
							-webkit-line-clamp: 10;
						}
					}
				}
			}

			.image-wrapper {
				margin: 0;
			}
		}
	}

	&__indicators {
		display: flex;
		cursor: pointer;
		gap: 1.375rem;
		align-items: center;
		margin: 0 auto;
		min-height: 2rem;

		@media (min-width: @screen-lg) {
			display: none;
		}

		&-button {
			border: 0;
			padding: 0;
			background: transparent;
			width: 0.5rem;
			height: 0.5rem;
			border-radius: 0.25rem;
			transition: var(--duration-default) ease-in-out;
			transform-origin: center;
			transition-property: background, border-radius, height, width;


			&:focus,
			&:focus-visible {
				outline: none;
			}

			&.is-active {
				position: relative;
				background: var(--linear-multicolor-gradient);
				width: 2rem;
				height: 2rem;
				border-radius: 1rem;
				display: flex;
				justify-content: center;
				align-items: center;

				.tabs__indicators-dot {
					opacity: 1;

					&::before {
						width: 1.75rem;
						height: 1.75rem;
						border-radius: 1.75rem;
					}
				}
			}
		}

		&-dot {
			display: block;
			opacity: 0.8;
			position: relative;
			width: 100%;
			height: 100%;

			&::before {
				position: absolute;
				content: '';
				display: block;
				width: 0.5rem;
				height: 0.5rem;
				border-radius: 0.25rem;
				background-color: var(--dark-background-primary);
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
				margin: auto;
				transition: var(--duration-default) ease-in-out;
				transform-origin: center;
				transition-property: background, border-radius, height, width;
			}

			&::after {
				position: absolute;
				content: '';
				display: block;
				width: 0.5rem;
				height: 0.5rem;
				border-radius: 0.25rem;
				background-color: #fff;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
				margin: auto;
			}
		}
	}
}

// Project Unity overrides
.layout {
	.tabs {
		--nav-padding: var(--space-4);
		--nav-gap: var(--space-8);
		--nav-gaps-amount: calc(var(--tabs-amount) - 1);
		--nav-button-width: calc((100% - 2 * var(--nav-padding) - var(--nav-gaps-amount) * var(--nav-gap)) / var(--tabs-amount));
		padding: var(--space-0);
		gap: var(--space-80);

		&__nav {
			border-radius: 3.75rem;
			border: 0.0625rem solid var(--tabbed-component-nav-border);
			background: var(--tabbed-component-nav-background);
			padding: var(--nav-padding);
			box-shadow: var(--tabbed-component-nav-shadow);
			justify-content: space-between;
			gap: var(--nav-gap);
		}

		&__button {
			--button-border-radius: 4.375rem;
			border-color: transparent;
			background: transparent;
			border-radius: var(--button-border-radius);
			box-shadow: none;
			transition-property: border, background, box-shadow;
			width: var(--nav-button-width);

			&:hover {
				border: 0.0625rem solid var(--tabbed-component-button-border);
			}
		
			&:focus,
			&:focus-visible {
				border: 0.0625rem solid var(--tabbed-component-button-border);
				outline: none;
			}

			&.is-active {
				border-radius: var(--button-border-radius);
				border: 0.0625rem solid var(--tabbed-component-button-border);
				background: var(--tabbed-component-button-background);
				box-shadow: var(--tabbed-component-button-shadow);
			}

			&-label {
				font-size: var(--octo-font-size-normal);
				font-weight: 500;
				line-height: 140%;
			}
		}

		&__container {
			border-radius: 0;
			background: transparent;

			&--outer-border,
			&--inner-border {
				padding: var(--space-0);
				border: 0;
			}

			&--outer-border {
				&::after {
					display: none;
				}
			}
		}

		&__card {
			margin: var(--space-0);
			border-radius: 0;

			&-label {
				&::after {
					background: var(--tabbed-component-button-border);
				}

				&-wrapper {
					border-color: var(--tabbed-component-nav-background);

					&:not(.tabs__card-label-wrapper--underline)::after {
						background-color: var(--tabbed-component-nav-background);
					}
				}
			}

			&-content {
				background: transparent;

				// Component specific
				.card {
					background: transparent;
				}

				.header {
					align-self: center;
					max-width: 36rem;
					
					&__layout {
						gap: var(--space-16);
					}

					&__badge {
						font-size: var(--h5);
						font-weight: 500;
					}
	
					&__heading {
						color: var(--input-text);
						font-size: 1.75rem;
					}
	
					&__subheading {
						font-size: var(--h5);
						font-weight: 400;
						color: var(--text-secondary);

						@media screen and (max-width: @screen-md) {
							font-size: var(--octo-font-size-normal);
						}
					}
				}
			}
		}

		&__indicators {
			&-button {
				&.is-active {
					background: var(--purple-blue-gradient);
				}
			}
		}
	}

	.flex-container.stripe:has(.tabs) {
		overflow: hidden;
	}
}